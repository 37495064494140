<template>
  <b-row>
    <b-col cols="12">
      <echart-area />
    </b-col>
    
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import EchartArea from './EchartArea.vue'

export default {
  components: {
    BRow,
    BCol,

    EchartArea,
  },
}
</script>
